@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700;900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    font-size: 14px;
    background-attachment: fixed;
}

a {
    color: #10A0E3;
}
a:hover, a:focus {
    color: #25a9b8;
}

hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.btn{
    text-transform: uppercase;
    font-size: 12px;
}

.main-cont {
    padding: 3.4rem 7rem 0 5rem;
}

.main-cont-2 {
    padding: 2rem 3rem;
}

.main-cont-plink{
    padding: 2rem 2.5rem 0rem 2.5rem;
}

.full-w {
    width: 100%;
}

.btn-group-2 .btn {
    width: 50%;
}

.btn {
    font-weight: bold;
}

.btn-lg {
    font-size: 15px;
    padding: 16px;
}

p.small-grey, label.small-grey{
    color: #b9b7b7;
    font-size: 12px;
    margin: 10px 0 20px 0;
    line-height: 18px;
    cursor: text;
}

p.small-grey-m0{
    color: #b9b7b7;
    font-size: 12px;
    margin: 0;
}

.m0{
    margin: 0!important;
}

p .small-grey-title{
    color: #b9b7b7;
    font-size: 15px;
    margin: 10px 0 20px 0;
}

/* btn colors */

.btn-primary, .btn-primary:focus , .btn-outline-primary:focus { 
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
    color: #ffffff;
}

.btn-primary:hover, .btn-outline-primary:hover {
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
}

.btn-outline-light {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-light:hover {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-primary{
    border-color: #10A0E3;
    color: #10A0E3;
}


.btn-success, .btn-success.disabled, .btn-success:disabled{
    background: #10A0E3;
    background: -webkit-radial-gradient(top left, #10A0E3, #0982C7);
    background: -moz-radial-gradient(top left, #10A0E3, #0982C7);
    background: radial-gradient(to bottom right, #10A0E3, #0982C7);
    border: none;
}

.btn-success:hover{
    background-color: #0982C7;
}

.btn-success:active, .btn-success:focus{
    box-shadow:0 0 0 .3rem rgba(0, 0, 0, 0.1)!important;
    background-color: #0982C7;
}

.btn-danger{
    background-color: rgb(255, 100, 82);
    border-color: rgb(255, 100, 82);
}


/* end btn colors */

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    letter-spacing: -0.6px;
    color: #333333;
}

.form-control{
    font-size: 14px!important;
}

.form-floating>.form-control, .form-floating>.form-select, .input-lg {
    height: 45px;
}

.form-floating>label {
    padding: 10px .75rem;
    font-size: 14px;
}

.form-floating-money>label {
    padding: 12px .75rem 8px 30px;
    font-size: 14px;
    overflow: hidden;
    line-height: 19px;
    text-align: left;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.75) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1rem;
    padding-bottom: .625rem;
}

.popover-body input.form-control, .popover-body select.form-control{
    font-size: 13px!important;
    height: 33px;
}

textarea.form-control{
    font-size: 13px!important;
}

select {
    height: 45px;
    font-size: 14px!important;
}

.form-control:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-control.input-error:focus{
    border-color: #dc3545;
    box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.1);
}

.card {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.05);
}

.card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.header-brand {
    max-width: 140px;
    margin-bottom: 20px;
}

.header-brand-nm {
    max-width: 140px;
    margin: 0;
}

.progress-steps div{
    background-color: #e3e2e2;
    border: solid 3px #ffffff;
    height: 10px;
}

.progress-steps div.active{
    background-color: #10A0E3;
}

.card-info-badge{
    position: absolute; 
    top: -5px; 
    right: -1px;
    font-weight: normal;
}

.card-brands img{
    margin-left: 6px;
    height: 11px;
}

.card-brands img.amex{
    height: 14px;
}

.card-brands img.discover{
    height: 13px;
}

.card-brands img.mastercard{
    height: 15px;
}

.wallets-accepted img {
    height: 14px;
}

.input-calendar, .input-cc, .input-user, .input-password{
    background-repeat: no-repeat;
    background-position: right 10px top 8px;
    background-size: 25px 25px;
}

.input-calendar{
    background-image: url('../images/calendar.svg');
    background-size: 22px 22px;
    background-position: right 10px top 10px;
    font-size: 12px!important;
}

.input-cc{
    background-size: 25px!important;
    padding-left: 45px!important;
    background-position: left 10px center!important;
}

.input-cc-cont label{
    margin-left: 34px;
}

.visa .input-cc{
    background-image: url('../images/visa.svg');
}

.jcb .input-cc{
    background-image: url('../images/jcb.svg');
}

.mastercard .input-cc{
    background-image: url('../images/mastercard.svg');
}

.discover .input-cc{
    background-image: url('../images/discover.svg');
    background-size: 56px;
    background-position: right 10px top 14px;
}

.american-express .input-cc{
    background-image: url('../images/amex.svg');
    background-size: 24px 24px;
    background-position: right 10px top 9px;
}

.diners-club .input-cc{
    background-image: url('../images/dinersclub.svg');
    background-size: 24px 24px;
    background-position: right 10px top 8px;
}

.unknown .input-cc, .unionpay .input-cc{
    background-image: url('../images/credit-card.svg');
    background-size: 24px 24px;
    background-position: right 10px top 8px;
}

.input-user{
    background-image: url('../images/avatar.svg');
    background-size: 25px 25px;
    background-position: right 10px top 8px;
}

.input-password{
    background-image: url('../images/asterisk.svg');
    background-size: 25px 25px;
    background-position: right 10px top 8px;
}


.sub-header{
    padding: 0;
}

.sub-header-line{
    padding: 0px 0 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.review-total{
    font-size: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.review-total .col:last-child{
    text-align: right;
}

.review-total .total-amount .col:last-child{
    font-size: 22px;
    text-align: right;
    font-weight: bold;
}

.review-total .total-amount .col:first-child{
    padding-top: 10px;
}

.top-border-grey{
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.bottom-border-grey{
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.top-border-grey-v2{
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    padding: 6px 0;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.bottom-grey{
    padding: 20px;
    background-color: rgb(249,249,249);
}

.icon-signal{
    max-width: 3.5rem;
    margin: 10px 0 16px 0;
}

input.big-input{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    font-size: 16px!important;
}

input.big-input:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.icon-edit{
   color: #10A0E3;
   font-size: 20px;
   cursor: pointer;
}

.icon-edit.right{
    position: absolute;
    right: 3rem;
}

ul.sortable, ul.sorted{
    list-style: none;
    padding-left: 0;
}

.sortable li, .sorted li{
    background-color: #ffffff;
    padding: 10px 10px 10px 40px;
    background-repeat: no-repeat;
    background-position: left center;
}

.payment-categories .sortable li{
    padding-right: 60px;
    margin-left: -40px;
    margin-right: -60px;
    position: relative;
}

.payment-categories .sorted li{
    padding-left: 10px;
}

.sortable-config li{
    padding: 6px 4px 0px 28px;
    margin-left: -28px;
}

.sortable li:hover{
    background-image: url('../images/bx-grid-vertical.svg');
}

.ui-sortable-helper{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px rgba(0,0,0,0.1);  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px rgba(0,0,0,0.1); 
}

.popover{
    max-width: 400px;
}

.popover-body{
    min-width: 380px;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);
  }
  
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }

  .popover-footer{
      border-top: 1px solid rgba(0,0,0,.2);
      margin: 15px -1rem 0 ;
      padding: 12px 1rem 0 1rem;
  }

  .popover-body .btn.btn-light{
    font-size: 10px;
  }

  .popover-footer .btn, .btn.bordered{
      margin-left: 8px;
      color:#000;
      background-color:#f9fafb;
      border-color:#f9fafb;
      box-shadow:0 0 0 .25rem rgba(211,212,213,.5);
      border: none;
  }

  .btn.bordered{
    color:#000;
    background-color:#f9fafb;
    border-color:#f9fafb;
    box-shadow:0 0 0 .25rem rgba(211,212,213,.5);
    border: none;
}
  
  .popover-footer .btn:focus{
      background-color:rgba(211,212,213,.25)
  }

  .btn.bordered:focus, .btn.bordered:hover{
        background-color:rgba(211,212,213,.25)
  }

  .popover-body label.header{
      
      font-weight: bold;
      margin-bottom: 6px;
      display: block;
  }

  .icheck-primary label, .popover-body label{
    font-size: 12px;
  }
  
  .popover-body .small-grey{
    font-size: 11px;
      margin-top: -4px;
      margin-bottom: 5px;
  }

  .card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.card-in.active{
    border-color: #000000;
}

.card-hover:hover{
    /*border-color: #10A0E3;*/
    cursor: pointer;
}

.card-body.card-header{
    background-color: #ffffff;
    border: none;
}

.grey-icon{
    color: rgba(0, 0, 0, 0.2);
}

.opacity-panel{
    opacity: 0.3;
    overflow: hidden;
}

.accordion-right-corner{
    right: 12px;
    top: 7px;
    position: absolute;
}

.alert{
    padding: 12px 1rem;
}

.payorFieldsConfig .icheck-primary{
    margin-top: 0!important;
}

.payorFieldsConfig small{
    font-size: 10px;
}

.payorFieldsConfig .row .p0{
    padding: 0 15px 0 0!important
}

.symbol{
    font-size: 20px;
}

.payorFieldsConfig .remove-icon-btn, .category-icon .remove-icon-btn{
    width: 23px;
    height: 23px;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../images/trash.svg');
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}

.payorFieldsConfig .remove-icon-btn:disabled, .payorFieldsConfig .remove-icon-btn[disabled]{
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: 0.4;
}

.bs-popover-right, .bs-popover-bottom{
    box-shadow: 0 0 0 5000px rgba(255,255,255,0.52);
    
}

.bs-popover-bottom{
    min-width: 680px;
}

.bs-popover-bottom .popover-body{
    min-width: 478px;
}

.cc-form input{
    border: none;
}

.cc-form input.form-control:focus{
    -webkit-box-shadow: none;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    none;  /* Firefox 3.5 - 3.6 */
    box-shadow:         none; 
}

.cc-form{
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin: 0;
}

.cc-form .cc-field{
    border: none;
    padding: 0;
}

.cc-form .cc-field input{
    padding: 0;
}

.cc-form .cc-field label{
    padding-left: 0;
    padding-right: 0;
}

.cc-form .cc-field:first-child label{
    padding-left: 12px;
}

.payment-categories{
    margin: 0 6rem;
}

.payment-categories .category-icon{
    right: 4px;
    padding-top: 0.5rem;
    text-align: right;
    position: absolute;

}
.payment-categories .remove-icon-btn{
    margin-left: 3px;
}

.form-control:disabled, .form-control[readonly]{
    background-color: #f3f3f3;
}

.input-money{
    background-image: url('../images/bx-dollar.svg');
    background-repeat: no-repeat;
    background-position: left 7px top 15px;
    background-size: 15px;
    padding-left: 25px!important;
}

.popover-body .input-money{
    background-position: left 7px top 8px;
}

.optional-pay{
    position: absolute; 
    background-color: #fff;
    z-index: 1;
    right: 15px;
    top: 16px;
}

.input-group-qty{
    width: 64px;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 22px;
}

.input-group-qty-big{
    top: -5px;
    margin: auto;
}

.input-group-qty-big .btn{
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty-big .btn:hover{
    background-color: #e6e6e6;
}

.input-group-qty-big input.form-control{
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty-big input.form-control:focus{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.input-group-qty-mr{
    right: 55px!important;
}

.input-group-qty .btn{
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-amount-upvalue{
    border: 0;
    font-size: 12px!important;
    text-align: right;
    padding: 0;
    display: inline;
}

.input-group-qty-inv{
    margin: auto;
}

.input-group-qty-inv .btn{
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty-inv .btn:hover{
    background-color: #e6e6e6;
}

.input-group-qty-inv input.form-control{
    height: 22px;
    padding: 0;
    text-align: center;
    font-size: 12px!important;
}

.input-group-qty-inv input.form-control:focus{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-outline-light {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-light:hover {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.input-group-qty .btn:hover{
    background-color: #e6e6e6;
}

.input-group-qty input.form-control{
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty input.form-control:focus{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.big-amount{
    width: 100%;
    border: none;
    color:#333333;
    text-align: center;
    background: transparent;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin: -15px 0 5px 0;
    border-bottom: solid 1px transparent;
}

.big-amount:focus{
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #cccccc;
    outline: none;
}

.icheck-primary>input:first-child:checked+input[type=hidden]+label::before, .icheck-primary>input:first-child:checked+label::before{
    background-color: #10A0E3;
    border-color: #10A0E3;;
}

.text-transform-normal{
    text-transform: none;
}

.main-top-bar{
    border-bottom: solid 1px #e7e6e6;
    background-color: #ffffff;
    left: 0;
    right: 0;
    position: fixed;
    padding: 0 0 0 190px;
    z-index: 10;
    top: 0;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
}

.main-left-bar{
    border-right: solid 1px #e7e6e6;
    background-color: #ffffff;
    padding: 16px 0px;
    position: fixed;
    min-width: 60px;
    height: 100%;
    z-index: 11;
    text-align: left;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
}

.main-left-bar a{
    font-size: 11px;
    padding: 0 24px;
    margin: 11px 0 11px 1px;
    color: #5a6a80!important;
    cursor: pointer;
    transition: all 0.8s ease;
    border-right: 2px solid transparent;
    display: block;
    width: 190px;
    border-left: 4px solid transparent;
    text-decoration: none;
}

.main-left-bar a:hover{
    border-left: 4px solid #10A0E3;
    color: #000000!important;
}

.main-left-bar ul li{
    list-style: none;
    font-size: 11px;
}

.main-left-bar ul li .header{
    margin-left: 24px;
    font-size: 10px;
    color: #414b59;
}

.main-left-bar ul li .icon{
    font-size: 15px;
    display: inline-block;
    margin-right: 12px;
}


.main-left-bar ul{
    padding-left: 0;
}

.main-body{
    padding: 0 44px 0 234px;
}

.mt-body{
    padding: 8.5rem 0 4rem;
}

.mt-body2{
    padding: 10rem 0 4rem;
}

.mt-body3{
    padding: 4.5rem 0;
}

.main-menu-bottom{
    bottom: 0;
    position: absolute;
}

.main-top-bar .btn{
    margin: 0 5px;
}

.main-top-bar .btn:last-child{
    margin-right: 0;
}
.main-top-bar .btn:first-child{
    margin-left: 0;
}

#main-branding{
    text-align: center;
}

#main-branding img{
    width: 100px;
    margin: 6px 0 30px;
}

.main-top-bar .top-bar-sub{
    border-top: solid 1px #e7e6e6;
    padding: 10px 30px;
}

.main-top-bar .top-bar-sub a:first-child{
    margin-left: 0;
}

.main-top-bar .top-bar-sub a:last-child{
    margin-right: 0;
}

.main-top-bar .top-bar-sub a{
    padding: 0;
    margin: 0 4px;
    color: #333333;
    transition: all 0.5s ease;
    font-size: 12px;
    text-decoration: none;
}

.main-top-bar a.icon svg{
    font-size: 21px!important;
}

.main-top-bar .profile-name{
    display: inline-block;
    margin-right: 20px;
    font-size: 13px;
}

.main-top-bar a.icon{
    margin: 0 6px!important;
    cursor: pointer;
}

.main-top-bar .top-bar-sub a svg{
    font-size: 16px;
    margin-right: 2px;
}

.main-top-bar .top-bar-sub a:hover{
    color: #008bce;
}

.main-top-bar .top-bar-sub input{
    height: 28px;
    font-size: 13px;
}

#main-loading-layer{
    z-index: 2000;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,1);
    text-align: center;
    color: #10A0E3;
    font-size: 36px;
}

#main-loading-layer svg{
    animation: rotation 2s infinite linear;
}

.table{
    font-size: 12px;
}

.table th{
    border-bottom-color: #dee2e6!important;
}

.table .dropdown .btn{
    line-height: 10px;
}

.table .dropdown a{
    font-size: 12px;
}

.table .dropdown a:active{
    background-color: #10A0E3;
}

.table .dropdown .dropdown-toggle::after{
    display: none;
}

.badge.bg-success{
    background-color: #88c952!important;
}

.dropdown button{

}

.payment-icon{
    width: 34px;
}

.card.card-in-border{
    border: solid 8px rgb(0, 0, 0, 0.05)
}

.card.card-selected{
    border: solid 2px rgb(0, 0, 0, 0.7)
}

.powered-by-pp-button-preview{
    font-size: 11px;
    color: #b9b7b7;
    margin-top: 10px;
}

.powered-by-pp-button-preview img{
    width: 45px;
    margin-left: 3px;
}

.payment-button-preview .btn{
    padding-left: 40px;
    padding-right: 40px;
}

.color-picker{
    position: absolute!important;
    height: 38px!important;
    padding: 0!important;
    border: none!important;
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
    background-color: transparent!important;
}

.color-picker span{
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
}

.color-picker-input{
    padding-left: 55px;
}

#pp-color-preview{
    text-align: center;
    padding: 2.48rem 3rem;
}

#pp-color-preview .button-cont{
    background-color: #ffffff;
    padding-bottom: 10px;
    
}

#pp-color-preview .button{
    color: #ffffff;
    font-size: 7px;
    padding: 5px;
    margin: 10px;
}

.toast-success-container{
    background-color: rgb(136, 201, 82)!important;
}

.toast-error-container{
    background-color: rgb(255, 100, 82)!important;
}

.toast-warning-container{
    background-color: rgb(255, 190, 82)!important;
}


input.input-error, select.input-error, textarea.input-error{
    border-color: #dc3545;
}

.cc-form.input-error{
    border-color: #dc3545;
}

.icon-modal{
    font-size: 60px;
    margin-bottom: 15px;
    color: #cccccc;
}

.modal-body{
    padding: 20px 40px;
}

.btn-back{
    color: #333333;
    position: absolute;
    margin: -30px 0 0 -56px;
}

.top-msg-container{
    position: fixed;
    background-color: rgba(47,47,51,1);
    padding: 4px 10px;
    color: #ffffff;
    z-index: 40;
    top: 0;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.bg-decorator{
    height: 50%;
    width: 100%;
    position: fixed;
    opacity: 0.1;
}

.react-datepicker__day--selected,.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background: #666666 !important;
}

.react-datepicker-wrapper{
    width: 100%;
}

.react-datepicker .react-datepicker__header button{
    border: solid 1px #cccccc;
}

.react-datepicker .react-datepicker__header select{
    border: solid 1px #cccccc;
    height: 25px;
}

.react-datepicker-popper .linkCalendar{
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.grey-icon-v2{
    opacity: 0.4;
}

.mr-1{
    margin-right: 10px;
}

.green-text{
    color: #92d050;
}

.places-autocomplete-dropdown-container{
    background-color: #ffffff;
    border: solid 1px #ced4da;
    border-radius: .25rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: -15px;
}

.places-autocomplete-dropdown-container .suggestion-item, .places-autocomplete-dropdown-container .suggestion-item-active{
   font-size: 12px;
   padding: 5px 10px;
   cursor: pointer;
}

.places-autocomplete-dropdown-container .suggestion-item-active{
   background-color: rgba(0, 0, 0, 0.05);
}
.icon-container {
    position: absolute;
    right: 12px;
    top: calc(50% - 14px);
}
.icon-container-10 {
    position: absolute;
    right: 12px;
    top: calc(50% - 10px);
}

.receipt-timeline{
    font-size: 35px;
    color: #e3e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.receipt-timeline .line{
    background-color: #e3e2e2;
    width: 10rem;
    height: 4px;
}

.receipt-timeline .line.active{
    background-color: #10A0E3;
}

.receipt-timeline .active, .receipt-timeline-labels .active{
    color: #10A0E3;
}

.receipt-timeline-labels{
    font-size: 11px;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.receipt-timeline-labels div{
   width: 6rem;
   text-align: center;
}

.confetti{
    display: flex;
    align-items: center;
    justify-content: center;
}

.refresh-icon{
    font-size: 22px;
    float: right;
    margin-top: -10px;
    cursor: pointer;
}

p.text-title-captcha{
    font-size: 13px;
    margin-top: 13px;
}

@media (min-width:960px){
    #vterminal-container{
        width: 45rem;
    }
}


@media (max-width:960px){
    #vterminal-container{
        width: 45rem;
    }

    #vterminal-container .main-card-body{
       padding: 3.4rem 4rem;
    }

    .vterminal-tabs{
        margin: -3.4rem -4rem 0rem -4rem!important
    }
}

@media (max-width:768px){
    .progress-steps div{
         border: solid 2px #ffffff;
    }
 }

@media (max-width:600px){
   
    #vterminal-container{
        width: 100%;
    }

    .payment-categories{
        margin: 0;
    }

    .mt-body3{
        padding: 10px;
     }

     #vterminal-container .card-body{
         padding: 2rem 1rem;
     }

     #vterminal-container li.ui-state-default{
         padding: 10px;
     }
     
     #vterminal-container .card-body.card-header{
         padding: 1rem ;
     }


}

@media (max-width:576px){
    .cc-form .cc-field:first-child{
        width: 100%;
        border-bottom: solid 1px #ced4da;
        border-right: none
    }

    .cc-form .cc-field{
        width: 33.333%;
        border-right: solid 1px #ced4da;
    }

    .cc-form .cc-field:last-child{
        border-right: none
    }
    
    .cc-form .cc-field input{
        padding: 0 10px;
    }

    .cc-form .cc-field label{
        padding-left: 10px;
    }

    .main-cont{
        padding: 40px 15px 0!important;
    }

}

@media (max-width:500px){
    .card-brands.accordion-right-corner{
        position: revert;
    }
}

